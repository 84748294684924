import { styled, useTheme } from '@mui/material';
import Logo from 'src/pages/common/Logo';

function SidebarLogo() {
  const theme = useTheme();
  const SidebarLogoWrapper = styled('div')(
    ({ theme }) => `
          width: ${theme.sidebar.width};
          min-width: ${theme.sidebar.width};
          text-align: center;
  `
  );
  return (
    <SidebarLogoWrapper>
      <Logo width={theme.sidebar.logoWidth}/>
    </SidebarLogoWrapper>
  );
}

export default SidebarLogo;
