import { ResourcesConfig } from "aws-amplify";

export const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USERPOOLID,
      userPoolClientId: process.env.REACT_APP_CLIENTID,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      signUpVerificationMethod: "code",
      allowGuestAccess: true,
      region: process.env.REACT_APP_REGION,
    },
  }
} as ResourcesConfig;
