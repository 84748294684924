import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));

const Login = Loader(lazy(() => import('src/pages/Login')));
const MediaManager = Loader(lazy(() => import('src/pages/MediaManager')));
const PlayListEditor1 = Loader(lazy(() => import('src/pages/PlayListEditor/PlayListEditor1')));
const PlayListEditor2 = Loader(lazy(() => import('src/pages/PlayListEditor/PlayListEditor2')));
const PlayListEditor3 = Loader(lazy(() => import('src/pages/PlayListEditor/PlayListEditor3')));
const PlayListEditor4 = Loader(lazy(() => import('src/pages/PlayListEditor/PlayListEditor4')));
const AccountSetting = Loader(lazy(() => import('src/pages/AccountSetting')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: 'Login',
        element: <Navigate to="/" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'SidebarLayout',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="MediaManager" replace />
      },
      {
        path: 'MediaManager',
        element: <MediaManager />
      },
      {
        path: 'PlayListEditor1',
        element: <PlayListEditor1 />
      },
      {
        path: 'PlayListEditor2',
        element: <PlayListEditor2 />
      },
      {
        path: 'PlayListEditor3',
        element: <PlayListEditor3 />
      },
      {
        path: 'PlayListEditor4',
        element: <PlayListEditor4 />
      },
      {
        path: 'AccountSetting',
        element: <AccountSetting />
      }
    ]
  }
];

export default routes;
