import { FC, useState, createContext, useEffect } from 'react';
import axios from 'axios'
import { getAuthToken } from "src/pages/common/cognito"

type SidebarContext = {
  sidebarToggle: any;
  playlistInfoArray: PlaylistInfoType[];
  toggleSidebar: () => void;
  closeSidebar: () => void;
  getPlaylistInfo: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

type PlaylistFileListType = {
  hash_filename: string
  original_filename: string
  display_start_at: number
  display_end_at: number
  expiry_at: number
};

type PlaylistInfoType = {
  id: number
  deploy_flg: boolean
  file_list: PlaylistFileListType[]
};

const defaultPlaylistInfo = [
  {
    id: 1,
    deploy_flg: false,
    file_list: []
  },{
    id: 2,
    deploy_flg: false,
    file_list: []
  },{
    id: 3,
    deploy_flg: false,
    file_list: []
  },{
    id: 4,
    deploy_flg: false,
    file_list: []
  }
];

export const SidebarProvider: FC = ({ children }) => {
  const [playlistInfoArray, setPlaylistInfoArray] = useState<PlaylistInfoType[]>(defaultPlaylistInfo);
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  async function getPlaylistInfo() {
    console.log("call getPlaylistInfo");
    //プレイリスト情報取得APIを呼ぶ
    const idToken = await getAuthToken();
    if(idToken == ""){
      console.log("cancel call getPlaylistInfo");
      return;
    }
    const headerdata = {
      Authorization: idToken,
    }
    await axios
      .get(process.env.REACT_APP_BASE_API_URL + "/getPlaylistInfo", { headers: headerdata })
      .then((res) => {
        console.log(res.data);
        if(res.data.result){
          setPlaylistInfoArray( res.data.playlist );
        }else{
          //TODO: APIのresultがfalseで帰ってきた場合の処理
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  useEffect( () => {
    getPlaylistInfo()
  },[]);
  

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, playlistInfoArray, toggleSidebar, closeSidebar, getPlaylistInfo }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
