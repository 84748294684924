import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { LoginUserProvider } from 'src/contexts/LoginUserContext';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

// cognito
import { amplifyConfig } from "./config/amplify-config";
import { Amplify } from 'aws-amplify';
Amplify.configure(amplifyConfig);

ReactDOM.render(
  <HelmetProvider>
    <LoginUserProvider>
      <SidebarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SidebarProvider>
    </LoginUserProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
