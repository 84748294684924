import * as Auth from "aws-amplify/auth";

export async function getAuthToken() {
  try{
    const session = await Auth.fetchAuthSession();
    const idToken = session.tokens.idToken.toString();
    console.log("session.tokens.idToken.payload.exp");
    console.log(session.tokens.idToken.payload.exp);
    return idToken;
  }catch{
    return "";
  }
}
