import { ReactNode } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

export interface ConfirmationDialogProps {
  id: string;
  keepMounted: boolean;
  open: boolean;
  onClose: (result: boolean) => void;
  title: string;
  message: string|ReactNode;
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { onClose, open, title, message, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {message}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant="outlined">
          キャンセル
        </Button>
        <Button onClick={handleOk} variant="contained">
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
