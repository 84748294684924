//import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';

import AuthenticatedGuard from './AuthenticatedGuard';

function App() {

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
        <CssBaseline />
        <AuthenticatedGuard />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
